@import url('https://fonts.googleapis.com/css?family=Unna:400,700');

html,
body {
	font-size: 2.5vw;
}
body {
	margin: 0;
	padding: 0;
	font-family: "Unna", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
	overflow:visible;
	text-align: center;
}

main {
	display: flex;
}

h1 {
	cursor: pointer;
	margin-bottom: 0;
	font-size: 3em;
}

h1:hover {
	color: #75002c;
}

h4 {
	margin-top: 0;
	font-weight: normal;
	font-size: 2em;
}

h4 span:hover {
	font-weight: bold;
}

h4 span:after {
	content: ' / ';
}

h4 span:last-of-type:after {
	content: '';
}

a {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: #75002c;
}

.along-lines {
	font-size: 1.5em;
}

.link:not(.along-lines) {
	margin-top: 1em;
	font-style: italic;
}

.link:last-of-type {
	font-size: 1.75em;
	margin-top: 1.5em;
	font-style: normal;
}

.home {
	position: relative;
}

.home .col {
	position: relative;
	width: 50vw;
}

.home .col:last-of-type {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	justify-content: center;
}

.home img {
	min-height: 100vh;
	min-width: 50vw;
	position: absolute;
	right: 0;
	top: 0;
	transition: filter 1.5s;
}

.home img:hover {
	filter: saturate(0);
}

.home .navlink {
	position: fixed;
	color: white;
	cursor: pointer;
	z-index: 10;
	left: 1em;
	top: 1em;
	font-weight: bolder;
	transition: color 1s;
	font-size: 3vw;
}

.home .navlink:hover {
	color: red;
}

.home .navlink.last {
	top: 2em;
}

main.videos {
	flex-direction: column;
	align-items: center;
}

.vid {
	width: 100vw;
	height: 56.25vw;
}

.videos h1 {
	cursor: pointer;
}

main.cv {
	text-align: left;
	flex-direction: column;
}

#page_1 {position:relative; overflow: hidden;margin: 93px 0px 122px 0px;padding: 0px;border: none;width: 816px;}
#page_2 {position:relative; overflow: hidden;margin: 93px 0px 253px 77px;padding: 0px;border: none;width: 739px;}
#page_2 #p2dimg1 {position:absolute;top:606px;left:93px;z-index:-1;width:377px;height:83px;}
#page_2 #p2dimg1 #p2img1 {width:377px;height:83px;}
.ft0{font: italic bold 21px 'Arial';line-height: 24px;}
.ft1{font: italic bold 13px 'Arial';text-decoration: underline;color: #e06666;line-height: 16px;}
.ft2{font: italic bold 13px 'Arial';line-height: 16px;}
.ft3{font: bold 15px 'Arial';line-height: 18px;}
.ft4{font: italic bold 15px 'Arial';line-height: 33px;}
.ft5{font: italic 15px 'Arial';line-height: 33px;}
.ft6{font: 15px 'Arial';line-height: 33px;}
.ft7{font: italic bold 15px 'Arial';line-height: 17px;}
.ft8{font: italic 15px 'Arial';line-height: 17px;}
.ft9{font: 15px 'Arial';line-height: 17px;}
.ft10{font: italic bold 15px 'Arial';line-height: 26px;}
.ft11{font: italic 15px 'Arial';line-height: 26px;}
.ft12{font: 15px 'Arial';line-height: 26px;}
.ft13{font: 15px 'Arial';color: #383838;line-height: 20px;}
.ft14{font: italic 15px 'Arial';color: #383838;line-height: 20px;}
.ft15{font: italic bold 15px 'Arial';color: #383838;line-height: 20px;}
.ft16{font: italic bold 15px 'Arial';color: #383838;line-height: 33px;}
.ft17{font: italic 15px 'Arial';color: #383838;line-height: 33px;}
.ft18{font: 15px 'Arial';color: #383838;line-height: 33px;}
.ft19{font: italic bold 15px 'Arial';color: #383838;line-height: 17px;}
.ft20{font: italic 15px 'Arial';color: #383838;line-height: 17px;}
.ft21{font: 15px 'Arial';color: #383838;line-height: 17px;}
.ft22{font: 15px 'Arial';text-decoration: underline;color: #383838;line-height: 17px;}
.ft23{font: bold 15px 'Arial';color: #383838;line-height: 18px;}
.ft24{font: italic bold 15px 'Arial';color: #383838;line-height: 29px;}
.ft25{font: italic 15px 'Arial';color: #383838;line-height: 29px;}
.ft26{font: 15px 'Arial';color: #383838;line-height: 29px;}
.ft27{font: 15px 'Arial';color: #38761d;line-height: 17px;}
.ft28{font: 15px 'Arial';color: #38761d;line-height: 20px;}
.p0{text-align: left;padding-left: 481px;margin-top: 0px;margin-bottom: 0px;}
.p1{text-align: right;padding-right: 125px;margin-top: 4px;margin-bottom: 0px;}
.p2{text-align: left;padding-left: 77px;margin-top: 31px;margin-bottom: 0px;}
.p3{text-align: left;padding-left: 77px;padding-right: 267px;margin-top: 8px;margin-bottom: 0px;}
.p4{text-align: left;padding-left: 77px;margin-top: 3px;margin-bottom: 0px;}
.p5{text-align: left;padding-left: 125px;padding-right: 125px;margin-top: 15px;margin-bottom: 0px;text-indent: -47px;}
.p6{text-align: left;padding-left: 125px;padding-right: 126px;margin-top: 0px;margin-bottom: 0px;text-indent: -47px;}
.p7{text-align: left;padding-left: 77px;margin-top: 34px;margin-bottom: 0px;}
.p8{text-align: left;padding-left: 77px;padding-right: 425px;margin-top: 7px;margin-bottom: 0px;}
.p9{text-align: left;padding-left: 77px;margin-top: 15px;margin-bottom: 0px;}
.p10{text-align: left;padding-left: 77px;padding-right: 354px;margin-top: 10px;margin-bottom: 0px;}
.p11{text-align: left;padding-left: 125px;padding-right: 126px;margin-top: 3px;margin-bottom: 0px;text-indent: -47px;}
.p12{text-align: left;padding-left: 77px;margin-top: 10px;margin-bottom: 0px;}
.p13{text-align: left;margin-top: 0px;margin-bottom: 0px;}
.p14{text-align: left;margin-top: 73px;margin-bottom: 0px;}
.p15{text-align: left;margin-top: 12px;margin-bottom: 0px;}
.p16{text-align: left;padding-left: 48px;padding-right: 126px;margin-top: 15px;margin-bottom: 0px;text-indent: -47px;}
.p17{text-align: left;padding-right: 216px;margin-top: 5px;margin-bottom: 0px;}
.p18{text-align: left;padding-left: 48px;padding-right: 126px;margin-top: 3px;margin-bottom: 0px;text-indent: -47px;}
.p19{text-align: left;margin-top: 1px;margin-bottom: 0px;}
.p20{text-align: left;padding-right: 134px;margin-top: 14px;margin-bottom: 0px;}
.p21{text-align: left;margin-top: 8px;margin-bottom: 0px;}
.p22{text-align: left;margin-top: 17px;margin-bottom: 0px;}
.p23{text-align: left;margin-top: 15px;margin-bottom: 0px;}
.p24{text-align: left;margin-top: 13px;margin-bottom: 0px;}
.p25{text-align: left;margin-top: 41px;margin-bottom: 0px;}
.p26{text-align: left;padding-left: 48px;padding-right: 126px;margin-top: 10px;margin-bottom: 0px;text-indent: -47px;}

@media screen and (min-width: 1200px) {
	html,
	body {
		font-size: 1.5vw;
	}
}

@media screen and (min-width: 2100px) {
	html,
	body {
		font-size: 1vw;
	}
}

@media screen and (max-width: 1200px) {
	.home h1:hover {
		font-size: 2em;
	}

	.home h4 {
		font-size: 1em;
	}
}

@media screen and (max-width: 700px) {
	html,
	body {
		font-size: 3.5vw;
	}
	
	.home {
		flex-direction: column-reverse;
	}

	.home .navlink {
		font-size: 1.5em;
		color: red;
	}

	.col {
		width: 100vw!important;
	}
}
